/* Agrega estas reglas de estilo para el fondo del componente */
.hero-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Ajusta el fondo móvil */
@media (max-width: 768px) {
  .hero-section {
    background-size: contain;
    background-position: top;
    height: auto; /* Ajusta la altura automáticamente */
  }
}
